<template>
  <section
    class="bg-center bg-no-repeat bg-cover"
    :class="props.classes"
    :style="backgroundStyle"
  >
    <div
      :class="[
        getCssClassByEnum(containerType),
        innerClasses,
        { 'mobile-no-padding': mobileNoPadding },
      ]"
    >
      <slot />
    </div>
  </section>
</template>

<script setup lang="ts">
import { EContainerType } from '@/@types/container-type';
/** Props */
const props = defineProps({
  classes: {
    type: String,
    required: false,
    default: () => {
      return '';
    },
  },
  innerClasses: {
    type: String,
    required: false,
    default: () => {
      return '';
    },
  },
  containerType: {
    type: String as PropType<
      | EContainerType
      | 'FULL_WIDTH'
      | 'PAGE'
      | 'CONTENT'
      | 'SHOP_PAGE'
      | 'SHOP_CONTENT'
    >,
    required: false,
    default: () => EContainerType.DEFAULT,
  },
  backgroundImage: {
    type: String,
    default: '',
  },
  mobileNoPadding: {
    type: Boolean,
    default: false,
  },
});

function getCssClassByEnum(containerType: EContainerType | string): string {
  switch (containerType) {
    case EContainerType.FULL_WIDTH:
      return 'container-basic__full-width';
    case EContainerType.PAGE:
      return 'container-basic__page';
    case EContainerType.CONTENT:
      return 'container-basic__content';
    case EContainerType.SHOP_PAGE:
      return 'container-basic__shop-page';
    case EContainerType.SHOP_CONTENT:
      return 'container-basic__shop-content';
    default:
      return '';
  }
}

const backgroundStyle = ref('');

if (props.backgroundImage.length) {
  backgroundStyle.value = `background-image: url('${props.backgroundImage}')`;
}
</script>

<style scoped lang="postcss">
.container-basic__full-width {
  @apply h-full w-full mx-auto;
}

.container-basic__content {
  @apply h-full w-full desktop:md:max-w-screen-md touch:md:max-w-screen-lg desktop:lg:max-w-screen-lg mx-auto;
}

.container-basic__page {
  @apply h-full w-full md:max-w-screen-xl mx-auto;
}

.container-basic__shop-page {
  @apply h-full w-full md:max-w-screen-2xl mx-auto px-sm;

  &.mobile-no-padding {
    @apply px-0 md:px-sm;
  }
}

.container-basic__shop-content {
  @apply h-full w-full px-0 desktop:md:max-w-screen-xl touch:md:max-w-screen-xl desktop:lg:max-w-screen-xl mx-auto desktop:sm:px-sm touch:px-0 desktop:md:px-md touch:md:px-md desktop:xl:px-0 touch:xl:px-0;
}
</style>
